<template>
    <BaseDialog :show="dialogToggle" title="Add Application" width="600px" :close="close">
        <template slot="content">
            <v-form class="grid-layout-column grid-gap-lg" ref="addApplicationForm" v-model="valid">
                <v-alert type="error" v-if="error" dense>
                    {{error}}
                </v-alert>

                <FormBanner :image="require('@/assets/add-application.png')">
                    Add an application.<br>Fast and Easy.
                </FormBanner>

                <ApplicationAutocomplete v-if="preselected" :edit="false" :selectedApplications="preselected" label="Application Name" group-by-integrations/>
                <ApplicationAutocomplete v-else :selectedApplications.sync="application" label="Application Name" group-by-integrations/>

                <div class="d-flex text-body-2 align-center" v-if="hasIntegration">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="showIntegration"/>
                    <div>Integrate application</div>
                </div>

                <!-- integrations do not allow the icon or admin to be set, manual or catalog apps do -->
                <div v-if="!willIntegrate">
                    <UserAutocomplete :selectedUsers.sync="form.administrators" multiple label="Application Administators" showEmail 
                        :rules="[ruleRequired, v => ruleMinLength(v, 1, 'administrator'), v => ruleMaxLength(v, 5, 'administrator' )]"/>

                    <AdvancedOptionsContainer class="mt-2">
                        <div class="grid-layout-column">
                            <FormTextField label="Application Login URL" v-model="form.loginUrl" :rules="[ruleUrl]" />
                            <IconFileInput label="Application Icon" :icon.sync="form.icon" :application="applicationWithIcon()"/>
                        </div>
                    </AdvancedOptionsContainer>
                </div>
            </v-form>
        </template>

        <template slot="actions">

            <v-btn color="primary" text @click="close">Cancel</v-btn>
            <div v-if="willIntegrate" class="ml-2">
                <v-btn color="primary" @click="addAndIntegrateApplication" :disabled="loading || !valid" elevation="0"><v-icon class="pr-1">mdi-open-in-new</v-icon> Connect and Add</v-btn>
            </div>
            <div v-else class="ml-2">
                <v-btn color="primary" @click="addApplication" :disabled="loading || !valid" elevation="0">Add</v-btn>
            </div>
        </template>
    </BaseDialog>
</template>

<script>
    import { mapActions } from "vuex"
    import BaseDialog from '@/components/dialog/BaseDialog.vue'
    import FormBanner from '@/components/FormBanner.vue'
    import UserAutocomplete from '@/components/UserAutocomplete.vue'
    import ApplicationAutocomplete from '@/components/ApplicationAutocomplete.vue'
    import FormTextField from '@/components/FormTextField.vue'
    import IconFileInput from '@/components/IconFileInput.vue'
    import AdvancedOptionsContainer from '@/components/AdvancedOptionsContainer.vue'

    export default {
        components: {
            BaseDialog,
            UserAutocomplete,
            ApplicationAutocomplete,
            FormBanner,
            FormTextField,
            IconFileInput,
            AdvancedOptionsContainer,
        },
        props: {
            toggle: {
                type: Boolean,
                required: true
            },
            // Preselected application, no manual selection
            preselected: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {               
                form: {
                    name: this.preselected ? this.preselected.name : '',
                    loginUrl: '',
                    icon: null,
                    administrators: [],
                    catalogApplicationId: this.preselected?.id ? this.preselected.id : null,
                    createTasks: false,
                },
                // Selected application when manually selecting from ApplicationAutoComplete
                application: '',
                // Selected application coming from either source
                selectedApplication: {},
                valid: true,
                loading: false,
                error: null,
                showIntegration: true,
            }
        },
        methods: {            
            ...mapActions(["updateOrgSettings"]),
            completed() {
                this.updateOrgSettings({ addApplicationsCompleted: true })
                this.$emit('addApplicationComplete')
            },
            close() {
                Object.assign(this.$data, this.$options.data())

                this.$refs.addApplicationForm.resetValidation()

                this.dialogToggle = false

                this.loading = false
            },
            addApplication() {
                // if the form is not valid, then don't submit
                if (!this.$refs.addApplicationForm.validate()) {
                    return
                }

                // if icon is null, then remove it from the form
                if (this.form.icon == null) {
                    delete this.form.icon
                }

                this.loading = true
                this.error = null

                this.$http.post('/api/v1/application', this.form).then(() => {
                    this.$root.$emit('toast', 'Application Added', 'success')
                    this.completed()
                    this.close()
                }).catch((error) => {
                    // if it's a 409, then the application already exists
                    if (error.response.status === 409) {
                        this.error = "application already exists"
                    } else {
                        this.error = "error adding application, please try again"
                    }

                    this.loading = false
                    console.log(error)

                })

            },
            addAndIntegrateApplication() {
                this.loading = true;
                this.error = null;

                const authWindow = window.open(`/api/v1/application/${this.form.catalogApplicationId}/oauth/request?type=api&createApp=true`, 'authWindow', 'width=500,height=800')

                // add a listener to the window to detect when it is closed
                const interval = setInterval(() => {
                    if (authWindow.closed) {
                        clearInterval(interval)
                        this.finish()
                    }
                }, 1000)
            },
            // finish is called when the OAuth flow is complete and the window is closed
            finish() {

                this.$http.get(`/api/v1/applications`).then((response) => {
                    let addedApp = response.data.applications.filter((app) => app.catalogApplicationId == this.form.catalogApplicationId)
                    if (addedApp.length != 1) {
                        this.error = "Failed to connect integration, please try again."
                        this.loading = false
                        return
                    }

                    let app = addedApp[0]
                    // TODO: how do we know if the OAuth flow was successful?
                    const payload = {
                        enabled: true,
                        importUsers: true,
                        authType: 'oauth2',
                    }

                    // save the provisioning configuration
                    this.$http.put(`/api/v1/application/${app.id}/provision/api`, payload)
                        .then( (r) => {
                            console.log(r)
                            if (r.data && r.data.isAuthorized) {
                                this.$root.$emit('toast', 'Integration connected', 'success')
                                this.completed()
                                this.close()
                            } else {
                                this.$root.$emit('toast', 'Failed to connect integration, please try again.', 'error')
                                this.close()
                            }
                        })
                        .catch(error => {
                            this.loading = false
                            this.error = "Failed to connect integration, please try again."
                            console.log(error)
                        })
                })

            },
            applicationWithIcon() {
                // to fill in the icon file preview
                if (this.application && typeof this.application === 'object') {
                    return {
                        ...this.form,
                        icon: this.application.icon,
                        domain: this.application.domain,
                    }
                }
               
                return this.form
            }
        },
        computed: {
            dialogToggle: {
                get() {
                    return this.toggle
                },
                set(value) {
                    this.$emit('update:toggle', value)
                }
            },
            hasIntegration() {
                return this?.selectedApplication?.hasIntegration
            },
            willIntegrate() {
                return this.hasIntegration && this.showIntegration
            }
        },
        watch: {
            preselected() {
                this.selectedApplication = {}
                this.form.catalogApplicationId = this.preselected.id
                this.form.name = this.preselected.name
                if (this.preselected?.id) {
                    this.$http.get(`/api/v1/applications/catalog/${this.preselected.id}`).then((response) => {
                        this.selectedApplication = response.data
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            },
            application() {
                // if application is a string, then it is a new application
                if (typeof this.application === 'string') {
                    this.form.catalogApplicationId = null
                    this.form.name = this.application
                    this.selectedApplication = {}
                }

                // if application is an object, then it is an existing application
                if (typeof this.application === 'object' && this.application !== null) {
                    this.form.name = this.application.name
                    this.form.loginUrl = this.application.loginUrl 
                    this.form.catalogApplicationId = this.application.id
                    this.selectedApplication = this.application
                }
            }
        }
    }


</script>
