<template>
    <v-data-table :items="users" :headers="headers" :search="search" :loading="loading" :custom-filter="customFilter">
        <template #[`item.name`]="{ item }"><UserCell :item="item.user"/></template>
        <template #[`item.idpStatus`]="{ item }"><UserEnrollmentCell :item="item.user" /></template>
        <template #[`item.email`]="{ item }">{{ item.user.email }}</template>
        <template #[`item.firstSeen`]="{ item }"><DateFormat :date-string="item.firstSeen" :date="true" /></template>
    </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import UserCell from '../User/UserCell.vue';
import UserEnrollmentCell from '@/components/UserEnrollmentCell.vue';
import DateFormat from "@/components/DateFormat.vue";

export default {
    props: {
        tokenName: {
            type: String,
            required: true
        },
        search: {
            type: String,
            required: false,
            default: ""
        }
    },
    computed: {
        ...mapGetters({
            gwsSamlConnected: "gwsSamlConnected",
            checkFeatureFlag: "checkFeatureFlag",
        })
    },
    mounted() {
        // don't show the enrollment state if the org isn't using the yeshid idp
        if (!this.gwsSamlConnected) {
            this.headers = this.headers.filter(e => e.value != 'idpStatus')
        }

        this.getUsers();
    },
    data() {
        return {
            loading: true,
            users: [],
            headers: [
                {
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Email",
                    value: "email",
                },
                {
                    text: "First seen",
                    value: "firstSeen",
                },
            ]
        };
    },
    methods: {
        getUsers() {
            if (!this.tokenName) {
                return;
            }
            this.loading = true;
            this.$http.get(`/api/v1/oauthgrants/users/${this.tokenName}`).then(response => {
                this.users = response.data
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        // needed because the search is not working with nested objects
        customFilter (value, search, item) {
            return item.user.email.toLowerCase().includes(search.toLowerCase()) || item.user.name.toLowerCase().includes(search.toLowerCase());
        }
    },   
    watch: {
        tokenName() {
            this.getUsers();
        }
    },

    components: { UserCell, UserEnrollmentCell, DateFormat }
}

</script>

