<template>
    <!-- dropdown menu with arrangeable table headers and csv export option -->
    <v-menu :close-on-content-click=false>
        <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                    <v-btn icon v-bind="{ ...attrsMenu, ...attrsTooltip }" v-on="{ ...onMenu, ...onTooltip }">
                        <v-icon>mdi-view-column-outline</v-icon>
                    </v-btn>
                </template>
                <span>Manage columns</span>
            </v-tooltip>
        </template>

        <v-list>

            <!-- for each header in the header array, make an entry in the menu -->
            <v-list-item v-for="header in filteredHeaders" :key="header.value" @dragstart="dragstart($event, header)"
                @dragover="dragover($event, header)" @dragend="dragend($event, header)" draggable="true">

                <v-list-item-action>
                    <v-icon>mdi-drag</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ header.text }}</v-list-item-title>
                <v-list-item-action>

                    <v-icon v-if="header.visible" @click="toggleHeader(header)">mdi-eye</v-icon>
                    <v-icon v-else @click="toggleHeader(header)">mdi-eye-off</v-icon>

                </v-list-item-action>
            </v-list-item>

        </v-list>
    </v-menu>
</template>

<script>

export default {
    props: {
        tableHeaders: {
            type: Array,
            required: true
        },
        tableName: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            draggedHeader: null,
            headerMenu: [],
            localHeaders: [],    // local copy of the table headers   
        }
    },
    mounted() {
        this.initializeHeaders()
    },
    watch: {
        tableHeaders: function (newVal, oldVal) {
            if (this.headerMenu.length === 0 && newVal.length && oldVal.length === 0) {
                this.initializeHeaders()
            }
        }
    },
    computed: {
        filteredHeaders() {
            // filter out pinned headers since the user can't move these columns
            return this.headerMenu.filter(h => !h.pinned)
        }
    },
    methods: {
        initializeHeaders() {

            // get the preferences from local storage
            let preferences = localStorage.getItem(this.tableName + '-table-preferences')
        
            if (preferences) {

                // parse the preferences string into an array
                preferences = JSON.parse(preferences)

                // clean up any duplicates from a previous bug
                const uniqueHeaders = preferences.filter(
                    (p, i) => i === preferences.findIndex(
                        check => p.value === check.value
                    )
                )

                uniqueHeaders.forEach(p => {

                    // find the header in the table headers array
                    let header = this.tableHeaders.find(h => h.value === p.value)

                    // if the header is found, push it to the headerMenu array
                    if (header) {

                        // if the header is visible, push a copy of the header to the localHeaders array
                        if (p.visible) {
                            this.localHeaders.push(header)
                        }

                        if (!p.pinned) {
                            this.headerMenu.push(p)
                        }

                    }
                })
            }

            // keep track of pinned headers
            const pinnedHeaders = this.pinnedHeaders()

            // now copy any headers that are not in the preferences array
            this.tableHeaders.forEach(h => {
                const isPinnedHeader = pinnedHeaders.some(p => p.value === h.value)
                if (isPinnedHeader) {
                    return
                }

                let header = this.headerMenu.find(p => p.value === h.value)

                if (!header && h.text !== '') {
                    this.headerMenu.push({
                        ...h,
                        text: h.text,
                        value: h.value,
                        visible: true
                    })
                    this.localHeaders.push(h)
                }
            })

            // now put pinned headers
            this.copyPinnedHeaders(this.localHeaders)

            // finally, emit the localHeaders array to the parent component
            this.$emit("update:tableHeaders", this.localHeaders)

        },
        pinnedHeaders(align) {
            if (align) {
                return this.tableHeaders.filter(h => h.pinned && (align && h.align === align))
            }
            return this.tableHeaders.filter(h => h.pinned)
        },
        copyPinnedHeaders(headers) {
            headers.unshift(...this.pinnedHeaders('start'))
            headers.push(...this.pinnedHeaders('end'))
        },
        toggleHeader(header) {
            header.visible = !header.visible
            this.savePreferences()
        },
        dragstart(event, header) {
            this.draggedHeader = header
        },
        dragover(event, header) {
            event.preventDefault()

            // get the index of the dragged header
            let draggedIndex = this.headerMenu.indexOf(this.draggedHeader)

            // get the index of the header being dragged over
            let overIndex = this.headerMenu.indexOf(header)

            // if the dragged header is being dragged over itself, do nothing
            if (draggedIndex === overIndex) {
                return
            }

            // remove the dragged header from the array
            this.headerMenu.splice(draggedIndex, 1)

            // insert the dragged header into the array at the index of the header being dragged over
            this.headerMenu.splice(overIndex, 0, this.draggedHeader)

        },
        dragend() {
            this.draggedHeader = null
            this.savePreferences()
        },
        savePreferences() {

            let headers = [...this.headerMenu]

            this.copyPinnedHeaders(headers)
            this.$emit("update:tableHeaders", headers.filter(h => h.visible))

            // save the headerMenu array to local storage
            localStorage.setItem(this.tableName + '-table-preferences', JSON.stringify(headers))
        },
    }
}

</script>
