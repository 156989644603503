
<template>
  <v-app>

    <v-system-bar app v-if="isAuthenticated && isAdmin && !gwsOauthConnected && isOnboarded" height="40" color="amber lighten-5">
      <v-icon class="ma-2" color="warning">mdi-alert</v-icon>
      <span>
        You must connect the <a href="/manage/integrations">Google integration</a> in order to see users, applications and
        other data in YeshID.
        <a href="https://yeshid.notion.site/fcfa23c566a24526b26e01ba991a4630" target="_blank">Learn more</a>
      </span>
    </v-system-bar>

    <v-system-bar app v-if="isAuthenticated && isAdmin && gwsOauthConnected && (gwsUpdateStatus == 'ERROR')" height="40" color="amber lighten-5">
      <v-icon class="ma-2" color="warning">mdi-alert</v-icon>
      <span>
        Your Google Workspace integration failed to sync.  Please see details in the
        <a href="/events">Events tab</a>.  You can disconnect and reconnect
        this integration from the
        <a href="/manage/integrations">Integrations tab</a>.
      </span>
    </v-system-bar>

    <v-navigation-drawer app permanent width="280" color="#fff" v-if="$route.meta.showNavigation">
      <v-list class="pa-4 pb-5">
        <v-list-item class="pa-0 ma-0" dense>
          <v-img src="@/assets/logo-black.svg" max-width="96" max-height="24" />
          <v-chip v-if="isAdmin" color="black" class="ml-6 white--text" small style="font-weight: 600;">Administrator</v-chip>
        </v-list-item>
      </v-list>
      <v-list class="py-0" v-for="link in navLinks()" :key="link.text">
        <v-spacer v-if="link?.meta?.separator" class="my-4"/>
        <div v-else>
          <v-list-group v-if="link?.children?.length" class="shorten-group-button" dense prepend-icon="$expand" color="#8c8c8c" append-icon="" active-class="group-open" :value="true">
            <template v-slot:activator>
              <v-list-item class="px-0 py-0" dense>
                <v-list-item-content>
                  <v-list-item-title style="text-transform: uppercase; color: #8C8C8C; font-size: 12px;">{{ link.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item class="px-2 mx-2 rounded" dense color="primary" v-for="sublink in link.children" :key="sublink.text" router :to="sublink.path" :target="sublink.target" :href="sublink.href">
              <v-list-item-action class="ma-0">
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content class="ml-2">
                <v-list-item-title style="font-weight: 600;">
                    <div>{{ sublink.text }}</div>
                    <div v-if="sublink.beta" style="position: absolute; top: 10px; right: 16px; background-color: #0ADCE7; color: #000; padding: 0 5px; line-height: 20px; font-size: 12px; font-weight: 600;" class="rounded ma-0">BETA</div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else-if="!link.enduserOnly || (link.enduserOnly && !isAdmin)" class="px-2 mx-2 rounded overflow-hidden" dense color="primary" router :to="link.path" :target="link.target" :href="link.href">
            <v-list-item-action class="ma-0">
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="ml-2">
              <v-list-item-title style="font-weight: 600;">{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>

      <template v-slot:append>
        <v-list class="py-3" bottom>

          <YeshIDOnboard v-if="isAdmin" class="my-2"></YeshIDOnboard>

          <v-list-item v-if="user.supportEmail">
            <v-list-item-content>
              <v-list-item-title >
                <span class="font-weight-bold text-body-2">Support</span>
                <div class="text-body-2"><a :href="'mailto:' + user.supportEmail">{{ user.supportEmail }}</a></div>  
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>

        <v-divider></v-divider>
        <v-menu offset-y top content-class="elevation-1">
          <template v-slot:activator="{ on, attrs }">
            <v-container class="d-flex align-center pa-0">
              <div class="ma-4">
                <UserAvatar :user="user" :size="36" color="primary"/>
              </div>
              <div>
                <div class="text-body-2 font-weight-bold text-truncate" style="max-width: 130px;"><UserName :user="user"/></div>
                <div data-cy="authenticated-user-email" class="text-truncate" style="font-size: 12px; max-width: 140px;">{{ user.email }}</div>
              </div>

              <v-btn icon v-bind="attrs" v-on="on" right fixed>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>

            </v-container>
          </template>
          <v-list dense>
            <v-list-item to="/logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Log out
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

    </v-navigation-drawer>

    <GlobalToast />

    <!-- we v-main so that the navigation layout works correctly -->
    <v-main class="main-bg" v-if="$route.meta.showNavigation">
        <router-view/>
    </v-main>
    <!-- for pages without navigation v-main prevents it from correctly being full-height -->
    <router-view v-else />

  </v-app>
</template>

<style lang="scss">
.shorten-group-button .v-list-item {
  align-content: center;
  max-height: 40px !important;
  min-height: 40px !important;
  padding: 0 8px;
  .v-list-group__header__prepend-icon {
    margin: 0;
    margin-right: 8px;
    align-self: center;
  }
}
.shorten-group-button {
  margin-top: 24px;
}
.group-open > div > .v-icon {
  transform: rotate(180deg);
}
</style>


<script>
import { mapGetters, mapActions } from "vuex";
import UserAvatar from "@/components/UserAvatar.vue";
import UserName from "./components/UserName.vue";
import GlobalToast from './components/GlobalToast.vue';
import YeshIDOnboard from './components/YeshIDOnboard.vue';
import { navConfig } from "@/router"

export default {
  components: {
    UserAvatar,
    UserName,
    GlobalToast,
    YeshIDOnboard,
  },
  computed: {
    ...mapGetters({
      user: "StateUser",
      isAuthenticated: "isAuthenticated",
      isAdmin: "isAdmin",
      isBackOfficeAdmin: "isBackOfficeAdmin",
      gwsOauthConnected: "gwsOauthConnected",
      gwsSamlConnected: "gwsSamlConnected",
      gwsUpdateStatus: "gwsUpdateStatus",
      isOnboarded: "isOnboarded",
      hasRole: "hasRole",
      userRoles: "userRoles",
      checkFeatureFlag: "checkFeatureFlag",
    })
  },
  watch: {
    isAuthenticated(newValue) {
      if (newValue && this.isAdmin) {
        this.updateBannerStatus()
      }
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.updateBannerStatus()
      this.fetchFeatureFlags()
      this.fetchOrgSettings()
    }

    this.$root.$on('syncWithGoogle', () => {
      this.syncWithGoogle()
    });
  },
  methods: {
    ...mapActions(["updateGwsOauthStatus", "updateGwsSamlStatus", "updateGwsUpdateStatus", "updateOnboarded", "fetchFeatureFlags", "fetchOrgSettings"]),
    navLinks() {
      return navConfig.reduce((acc, curr) => {
        const checkAccess = (l) => {
          let hasAccess = true
          if (l?.meta?.backOfficeAdminRequired) {
            hasAccess = hasAccess && this.isBackOfficeAdmin
          }

          if (l?.meta?.adminRequired) {
            // If role access is available, check that as well
            hasAccess = hasAccess && (this.isAdmin || l?.meta?.hasRoleAccess?.())

          }

          if (l?.meta?.ssoRequired) {
            hasAccess = hasAccess && this.gwsSamlConnected
          }

          if (l?.meta?.hasAdditionalAccess) {
            hasAccess = hasAccess && l.meta.hasAdditionalAccess()
          }

          return hasAccess
        }

        if (!checkAccess(curr)) {
          return acc
        }

        const filteredLink = {
          ...curr,
          children: curr.children ? curr.children.filter(c => checkAccess(c) && c.text) : []
        }

        return acc.concat([filteredLink])
      }, [])
    },
    updateBannerStatus() {
      this.$http.get("/api/v1/integrations/google/status").then(response => {
        this.updateGwsOauthStatus(response.data.isOauthConnected)
        this.updateGwsSamlStatus(response.data.isSamlConnected)
        this.updateGwsUpdateStatus(response.data.updateStatus)
      })
    },
    syncWithGoogle() {
      var loc = window.location, new_uri;
      if (loc.protocol === "https:") {
          new_uri = "wss:";
      } else {
          new_uri = "ws:";
      }
      new_uri += "//" + loc.host;
      new_uri += '/api/v1/integrations/google/import';

      let c = new WebSocket(new_uri)

      let self = this

      this.$root.$emit('toast', 'Syncing with Google', 'progress')

      c.onmessage = function (msg) {
          var j = JSON.parse(msg.data);

          if (j.error) {
            self.$root.$emit('toast', j.error, 'error')
            self.$root.$emit('syncedWithGoogle')
          } else if (j.completed) {
            self.$root.$emit('toast', 'Sync with Google completed', 'success')
            self.$root.$emit('syncedWithGoogle')
          } else if (j.hasProgress) {
            self.$root.$emit('toast', `Syncing with Google (${(j.step / 10 * 100) | 0}%)`, 'progress')
          }
      }

      // handle connection errors
      // don't bother dealing with ws error codes, they are useless
      c.onerror = function (e) {
        console.log('error connecting to websocket', e)
        self.$root.$emit('toast', 'connection error, please try again', 'error')
        self.$root.$emit('syncedWithGoogle')
      }
    },
  },
  metaInfo: {
    title: 'YeshID'
  },
}
</script>
