<template>
    <div>
        <InputLabelDescription :label="label" :optional="optional" :description="description"/>
        <v-text-field :data-cy="dataCy" v-bind="$props" v-on="$listeners" class="text-body-2" :label="unfocusedPlaceholder" dense single-line validate-on-blur
            clear-icon="mdi-close-circle" v-show="edit">
            <slot v-for="(_, name) in $scopedSlots" :name="name" :slot="name"></slot>
        </v-text-field>

        <div v-show="!edit">
            <template v-if="value">
                <a :data-cy="dataCy + '-link'" v-if="link" :href="value" target="_blank" class="text-body-2" style="text-decoration: none;">{{ value }}</a>
                <span :data-cy="dataCy + '-label'" v-else class="text-body-2">
                    <span v-if="type === 'password'">********</span>
                    <span v-else>{{ value }}</span>
                </span>
            </template>
        </div>
    </div>
</template>


<script>
import { VTextField } from 'vuetify/lib'
import InputLabelDescription from './InputLabelDescription.vue';

export default {
    name: "FormTextInput",
    extends: VTextField,
    props: {
        label: {
            type: String,
            default: "",
        },
        optional: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        "data-cy": {
            type: String,
            default: "",
        },
        edit: {
            type: Boolean,
            default: true,
            required: false,
        },
        link: {
            type: Boolean,
            default: false,
            required: false,
        },
        description: {
            type: String,
            required: false,
            default: "",
        },
        hideDetails: {
            type: [String, Boolean],
            default: "auto",
            required: false,
        },
        unfocusedPlaceholder: {
            type: String,
            default: "",
        }
    },
    components: { InputLabelDescription }
}
</script>