<template>
    <FilterDropdownSelector v-bind="$props" v-on="$listeners" :filter.sync="filterConfig">
        <template v-slot:item="item">
            <UserEnrollmentCell :item="item.data"/>
        </template>
    </FilterDropdownSelector>
</template>

<script>
import FilterDropdownSelector from '@/components/filters/FilterDropdownSelector.vue'
import UserEnrollmentCell, { enrollmentStatesConfig } from '@/components/UserEnrollmentCell.vue'

const text = "Enrollment status"
const type = "enrollmentStatus"

export default {
    extends: FilterDropdownSelector,
    props: {
    },
    data() {
        return {
            filterConfig: 
                this.$api.filters.buildFilter(text, type,
                    Object.keys(enrollmentStatesConfig).map(id =>
                        this.$api.filters.buildFilterItem("idpStatus", id, enrollmentStatesConfig[id].text, "=", { idpStatus: id })
                    ),
                ),
        }
    },
    components: {
    FilterDropdownSelector,
    UserEnrollmentCell
},
    type,
    text, 
}
</script>