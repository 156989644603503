<template>
    <FilterDropdownSelector v-bind="$props" v-on="$listeners" :filter.sync="filterConfig">
        <template v-slot:item="item">
            <UserSecureStatusVue :user="item.data"/>
        </template>
    </FilterDropdownSelector>
</template>

<script>
import UserSecureStatusVue, { secureStatusConfig } from '@/components/UserSecureStatus.vue'
import FilterDropdownSelector from '@/components/filters/FilterDropdownSelector.vue'

const text = "Secure status"
const type = "secureStatus"

export default {
    extends: FilterDropdownSelector,
    props: {
    },
    data() {
        return {
            filterConfig: 
                this.$api.filters.buildFilter(text, type,
                    secureStatusConfig.map(c =>
                        this.$api.filters.buildFilterItem(c.filter.type, c.filter.value, c.text, "=", { [c.filter.type]: c.filter.value })
                    ),
                ),
        }
    },
    components: {
    FilterDropdownSelector,
    UserSecureStatusVue
},
    type,
    text, 
}
</script>