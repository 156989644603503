<template>
    <div class="grid-layout-column">
        <div v-if="edit">
            <InputLabelDescription :label="label" :optional="optional" :description="description"/>

            <v-combobox
                v-model="mySelectedApplications"
                :items="filteredApplicationList"
                :disabled="loading"
                :loading="loading"
                :filter="() => true" 
                outlined
                clearable
                chips
                item-text="name"
                item-value="name"
                return-object
                :multiple="multiple"
                dense
                :search-input.sync="search"
                :rules="rules"
                :hide-details="hideDetails"
                :menu-props="{ ...((!search && !multiple && mySelectedApplications?.name) && { value: false }) }"
                @update:search-input="handleSearchUpdate"
                >

                <template v-if="!multiple" v-slot:selection="data">
                    <!-- show the chip if the application is in the list of selected applications, otherwise show the name of the application that was manually entered -->
                    <span v-if="typeof data.item === 'string'">{{ data.item }}</span>
                    <ApplicationNameCell v-else :application="data.item" />
                </template>
                <template v-else v-slot:selection=""></template>

                <template v-if="!multiple" v-slot:item="data">
                    <ApplicationNameCell :application="data.item" />
                </template>
                <template v-else v-slot:item="{ item, on, attrs }">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                            <v-icon>
                                {{ mySelectedApplications.map(x => x.id).includes(item.id) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <ApplicationNameCell :application="item" />
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template slot="no-data">
                    <v-list class="pa-0 text-body-2">
                        <v-list-item>
                            No results found.
                        </v-list-item>
                        <div v-if="search">
                            <v-divider/>
                            <v-list-item class="pt-2">
                                <BaseChip class="mr-2">{{ search }}</BaseChip>
                                <a @click="selectManualApp(search)">+ Add as application</a>
                            </v-list-item>
                        </div>
                    </v-list>
                </template>

            </v-combobox>
        </div>

        <div class="grid-layout grid-basis-auto" v-if="multiple && mySelectedApplications.length">
            <ApplicationChip v-for="app in selectedApplications" :key="app.id" v-on="edit ? { 'click:close': () => mySelectedApplications = mySelectedApplications.filter(a => (a.id && a.id != app.id) || (a.name != app.name)) } : {}" :application="app"/>
        </div>
        <div v-else-if="!edit && !multiple && mySelectedApplications">
            <ApplicationChip :application="mySelectedApplications"/>
        </div>
    </div>
</template>

<script>
    import ApplicationChip from './ApplicationChip.vue'
    import BaseChip from './BaseChip.vue'
    import InputLabelDescription from './InputLabelDescription.vue'
    import ApplicationNameCell from '@/components/table/ApplicationNameCell.vue'

    export default {
        name: "ApplicationAutocomplete",
        components: {
            ApplicationChip,
            InputLabelDescription,
            ApplicationNameCell,
            BaseChip,
        },
        props: {
            applications: {
                required: false,
                default: null,
            },
            selectedApplications: {
                required: true,
            },
            label: {
                type: String,
                default: "Select Application",
            },
            subLabel: {
                type: String,
                default: ""
            },
            optional: {
                type: Boolean,
                default: false,
            },
            description: {
                type: String,
                required: false,
                default: "",
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            edit: {
                type: Boolean,
                default: true,
                required: false,
            },
            hideDetails: {
                type: [String, Boolean],
                default: "auto",
                required: false,
            },
            groupByIntegrations: {
                type: Boolean,
                default: false,
                required: false,
            }
        },
        data() {
            return {
                // keep track of what the user types in for display
                search: "",
                // the full catalog of applications
                catalogApplications: [],
                // the list of applications after filtering
                filteredApplications: [],
                // loading indicator
                loading: false,
                // validation rules
                // TODO fix rules mixins to support objects and string
                rules: [
                    (value) => {
                        if (this.optional) {
                            return true
                        }

                        if (!this.multiple) {
                            if (typeof value === 'string') {
                                return value.length >= 3 && value.length <= 50 || 'Please enter a valid application name'
                            }
                            if (value && typeof value === 'object') {
                                return this.applicationList.some(a => a.id === value.id) || 'Please select an application'
                            }
                            return 'Please select an application or enter a new one'
                        }

                        if (Array.isArray(value)) {
                            return !!value.length || 'Please select at least one application'
                        }
                        if (typeof value === 'object') {
                            return this.applicationList.includes(value) || 'Please select an application'
                        }

                        return 'Please select an application or enter a new one'
                    },
                ],       
            }
        },
        methods: {
            handleSearchUpdate(val) {
                if (val && !this.multiple && this.selectedApplications?.name) {
                    this.$emit('update:selectedApplications', null)
                }
            },
            selectManualApp(name) {
                const newApp = { name }
                if (this.multiple) {
                    const selected = this.selectedApplications.concat([newApp])
                    this.$emit('update:selectedApplications', selected)
                } else {
                    this.$emit('update:selectedApplications', name)
                }
                this.search = ""
            },
            getApplications() {
                if (this.catalogApplications.length > 0) {
                    return
                }
                
                this.loading = true

                this.$http.get('/api/v1/applications/catalog').then((response) => {
                    if (this.groupByIntegrations) {
                        let appsWithIntegrations = response.data.applications.filter((app) => app.hasIntegration)
                        let appsWithoutIntegrations = response.data.applications.filter((app) => !app.hasIntegration)
                        this.catalogApplications = [
                            {header: "Automated user management"},
                            ...appsWithIntegrations,
                            {header: "Manual user management"},
                            ...appsWithoutIntegrations,
                        ]
                    } else {
                        this.catalogApplications = response.data.applications
                    }
                    this.loading = false
                }).catch((error) => {
                    this.$root.$emit('toast', 'Unable to fetch applications', 'error')
                    console.log(error)
                })
            },
            returnHeadersAndFilter(item, queryText, itemText) {
                if (item.header) {
                    return true
                }
                return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
            },
        },
        mounted() {
            if (!this.applications) {
                this.getApplications()
            }
        },
        computed: {
            filteredApplicationList() {
                const apps = this.applicationList.filter(a => 
                    this.returnHeadersAndFilter(a, this.search || "", a.name)
                ) 

                // if some of the "apps" do not have a header then return the apps list
                if (apps.some(a => !a.header)) {
                    return apps
                }
                // otherwise return an empty array to display the empty state
                return []
            },
            applicationList() {
                // use the applications we were provided, otherwise the catalog
                return this.applications || this.catalogApplications
            },
            mySelectedApplications: {
                get() {
                    return this.selectedApplications
                },
                set(value) {
                    let v = value
                    if (this.multiple) {
                        // when we're selecting multiple it doesn't make sense to allow the user to type in something custom
                        // (and pretty much would break whatever api wants the data)
                        v = value.filter(a => typeof a == "object")
                    }
                    this.$emit('update:selectedApplications', v)
                }
            }
        }
    }

</script>