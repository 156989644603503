<template>
    <div class="align-center grid-layout grid-basis-auto ml-2">
        <FormTextField v-if="showSearch" v-model="searchValue" placeholder="Search" single-line autofocus
            hide-details/>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="$event => showSearch = !showSearch" icon>
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </template>
            <span>Search table</span>
        </v-tooltip>
    </div>
</template>

<script>
import FormTextField from './FormTextField.vue'

export default {
    components: {
        FormTextField,
    },
    props: {
        search: {
            type: String,
            required: true,
        },
    },
    data: function() {
        return {
            searchValue: "",
            showSearch: false,
        }
    },
    mounted() {
        this.searchValue = this.search
    },
    watch: {
        searchValue: function(newValue) {
            this.searchValue = newValue
            this.$emit('update:search', newValue)
        }
    }
}
</script>