<template>
    <v-alert
        class="alert-banner ma-0 px-4 py-3" :color="backgroundColor" :icon="false" align-items="top" :rounded="rounded">
        <div :class="`text-body-2 ${slotTextColor}`">
            <slot/>
        </div>
        <template slot="prepend">
            <v-icon class="mr-2" size="20" :color="iconColor">{{ alertIcon }}</v-icon>
        </template>
    </v-alert>
</template>

<script>

const alertTypes = {
    "info-grey": {
        backgroundColor: "info-bg",
        textColorClass: "info-text--text",
        iconColor: "info-icon",
        icon: "mdi-information",
    },
    info: {
        backgroundColor: "blue-bg",
        textColorClass: "blue-text--text",
        iconColor: "blue-icon",
        icon: "mdi-information",
    },
    error: {
        backgroundColor: "error-bg",
        textColorClass: "error-text--text",
        iconColor: "error-icon",
        icon: "mdi-alert",
    },
    warning: {
        backgroundColor: "warning-bg",
        textColorClass: "warning-text--text",
        iconColor: "warning-icon",
        icon: "mdi-alert",
    }
}
export default {
    name: "AlertBanner",
    props: {
        type: {
            type: String,
            default: "info",
        },
        rounded: {
            default: "md",
        }
    },
    computed: {
        alertIcon() {
            return alertTypes[this.type].icon
        },
        backgroundColor() {
            return alertTypes[this.type].backgroundColor
        },
        slotTextColor() {
            return alertTypes[this.type].textColorClass
        },
        iconColor() {
            return alertTypes[this.type].iconColor
        }
    }
}
</script>

<style>
.alert-banner .v-alert__wrapper {
    align-items: flex-start;
}
</style>