<template>
    <div class="mb-2" v-if="label || description">
        <span class="text-body-2"><b>{{ label }}</b></span>
        <span class="pl-2 text-body-2 color-grey" v-if="optional">(Optional)</span>
        <div v-if="description" class="text-caption" v-html="description"></div>
    </div>
</template>

<script>
export default {
    props: {
        description: {
            type: String,
            required: false,
            default: "",
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        optional: {
            type: Boolean,
            required: false,
            default: false,
        },
    }
}
</script>