<template>
    <div>
        <v-hover v-slot="{ hover }">
            <span class="d-flex" @click="openMenu" style="cursor: pointer">
                <ApplicationNameCell :application="application" :size="16"/>
                <v-tooltip v-if="icon" top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="ml-1" size="16">{{ icon }}</v-icon>
                    </template>
                    <span>{{ tooltip }}</span>
                </v-tooltip>
                <v-icon class="ml-1" dense color="#aaa" :style="`border: 1px solid ${hover ? '#aaa' : 'transparent'}; border-radius: 6px;`">mdi-menu-down</v-icon>
            </span>
        </v-hover>
        <v-menu v-model="showMenu" :position-x="menuX" :position-y="menuY" absolute offset-y>
            <v-list>
                <v-list-item @click="selectAll">
                    <v-list-item-title>Select all</v-list-item-title>
                </v-list-item>
                <v-list-item @click="unselectAll">
                    <v-list-item-title>Unselect all</v-list-item-title>
                </v-list-item>
                <v-list-item @click="importFromCSV" v-if="!application.orgApplicationProvisioningConfig && !checkFeatureFlag('new-csv-import')">
                    <v-list-item-title>Import from CSV</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApplicationNameCell from '@/components/table/ApplicationNameCell.vue';

export default {
    components: { ApplicationNameCell },
    props: {
        application: {
            type: Object,
            default: Object,
            required: true,
        }
    },
    data() {
        return {
            showMenu: false,
            menuX: 0,
            menuY: 0,
        }
    },
    computed: {
        ...mapGetters({
            checkFeatureFlag: "checkFeatureFlag",
        }),
        icon() {
            if (!this.application.catalogApplicationId) {
                return 'mdi-wrench-outline'
            } else if (this.application.orgApplicationProvisioningConfig) {
                return 'mdi-power-plug-outline'
            }

            return ''
        },
        tooltip() {
            if (!this.application.catalogApplicationId) {
                return 'Manually Added'
            } else if (this.application.orgApplicationProvisioningConfig) {
                return 'Integrated'
            }

            return ''
        }
    },
    methods: {
        openMenu(e) {
            this.showMenu = true
            this.menuX = e.x
            this.menuY = e.y
        },
        selectAll() {
            this.$emit('selectAll')
        },
        unselectAll() {
            this.$emit('unselectAll')
        },
        importFromCSV() {
            this.$emit('importFromCSV', this.application)
        }
    }
}
</script>