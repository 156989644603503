<template>
    <div v-if="config.text" class="d-flex align-left">
        <v-chip :color="config.color">
            <v-img v-if="config.img" class="mr-2" :src="config.img" max-width="16" max-height="16"/>
            <v-icon v-else left :color="config.iconColor">{{ config.icon }}</v-icon>
            {{ config.text }}
        </v-chip>
    </div>
</template>

<script>
const initialState = {
    color: "#f4f4f4",
    textColor: "black",
    icon: "mdi-circle-medium",
    iconColor: "grey",
}

const notSecure = {
    ...initialState,
    text: "No 2FA",
    color: "#FBE6E8",
    iconColor: "error",
    filter: {
        type: "hasGoogleTwoStepVerification",
        value: false,
    }
}

export const secureStatusConfig = [{
    ...initialState,
    text: "Secure",
    color: "#E3F2FD",
    img: require("@/assets/logo-icon.svg"),
    filter: {
        type: "status",
        value: "ACTIVE",
    },
}, {
    ...initialState,
    text: "Google 2FA",
    img: require("@/assets/google-logo.svg"),
    filter: {
        type: "hasGoogleTwoStepVerification",
        value: true,
    },
}, notSecure]

export default {
    name: "UserSecureStatus",
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    computed: {
        config: {
            get() {
                for (const c of secureStatusConfig) {
                    if (c.filter && this.user[c.filter.type] === c.filter.value) {
                        return c
                    }
                }
                return notSecure
            }
        }
    }
}
</script>
